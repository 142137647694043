import { Coupon } from '@wix/ambassador-ecommerce-coupons-v2-coupon/types';
import { CouponNames } from '@wix/loyalty-coupon-names';

interface BuildClaimedCouponTitleParams {
  rewardName: string;
  coupon: Coupon;
}

export type ClaimedTitleBuildFn = ReturnType<typeof createClaimedCouponTitleBuilder>;

export const createClaimedCouponTitleBuilder =
  (couponNames: CouponNames) =>
  ({ rewardName, coupon }: BuildClaimedCouponTitleParams) => {
    return `${rewardName} - ${couponNames.getCouponSubtitle({
      coupon,
      referenceCoupon: true,
    })}`;
  };
