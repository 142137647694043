import { RewardType } from '@wix/ambassador-loyalty-v1-reward/types';

import { SimpleReward } from '../types/domain';
import { isRewardAvailable } from './isRewardAvailable';

interface SortRewardsParams {
  rewards: SimpleReward[];
  pointsBalance: number;
}

const rewardTypeOrder: RewardType[] = [
  RewardType.DISCOUNT_AMOUNT,
  RewardType.SPI_DISCOUNT_AMOUNT,
  RewardType.COUPON_REWARD,
];

export function sortRewards({ rewards, pointsBalance }: SortRewardsParams): SimpleReward[] {
  return rewards.slice().sort((first, second) => {
    const isFirstRewardAvailable = isRewardAvailable({ requiredPoints: first.requiredPoints, pointsBalance });
    const isSecondRewardAvailable = isRewardAvailable({ requiredPoints: second.requiredPoints, pointsBalance });

    // Sort by availability
    if (isFirstRewardAvailable && !isSecondRewardAvailable) {
      return -1;
    }

    if (!isFirstRewardAvailable && isSecondRewardAvailable) {
      return 1;
    }

    // Sort by activation
    if (first.couponActivated && !second.couponActivated) {
      return -1;
    }

    if (!first.couponActivated && second.couponActivated) {
      return 1;
    }

    // Sort by type
    if (first.type !== second.type) {
      const firstRewardOrderIndex = rewardTypeOrder.indexOf(first.type!);
      const secondRewardOrderIndex = rewardTypeOrder.indexOf(second.type!);

      return firstRewardOrderIndex - secondRewardOrderIndex;
    }

    // Sort by date created
    if (first.dateCreated && second.dateCreated) {
      return second.dateCreated.getTime() - first.dateCreated.getTime();
    }

    return 0;
  });
}
