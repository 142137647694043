import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Reward } from '@wix/ambassador-loyalty-v1-reward/types';
import { loyaltyUouApplyRewardClick } from '@wix/bi-logger-loyalty-uou/v2';

import { SimpleReward } from '../../../../../../types/domain';
import { ThunkApiConfig } from '../../../../../../types/store';
import {
  navigateToBookingsPage,
  navigateToHomePage,
  navigateToNewRestaurantsPage,
  navigateToOldRestaurantsPage,
} from '../../../../../../utils';

export interface RewardsConfigProps {
  rawRewards: Reward[];
  simpleRewards: SimpleReward[];
}

const initialState: RewardsConfigProps = {
  rawRewards: [],
  simpleRewards: [],
};

const applyReward = createAsyncThunk<void, SimpleReward, ThunkApiConfig>(
  'rewards/applyReward',
  async (simpleReward, { extra, getState, rejectWithValue }) => {
    const {
      rewardsConfig: { rawRewards },
      accountConfig: { account },
      appInstallStatusConfig,
    } = getState();
    const { flowAPI, wixCodeApi } = extra;
    const { isViewer } = flowAPI.environment;

    const reward = rawRewards?.find(({ id }) => id === simpleReward.id);
    if (!reward) {
      return;
    }

    if (isViewer && reward.id) {
      const params = new URLSearchParams(wixCodeApi.location.url);
      flowAPI.bi?.report(
        loyaltyUouApplyRewardClick({
          rewardId: reward.id,
          rewardName: reward.name,
          rewardType: reward.type,
          totalPoints: account.pointsBalance!,
          referralInfo: params.has('referralInfo') ? params.get('referralInfo') ?? '' : '',
        }),
      );
    }

    const { isOldRestaurantsAppInstalled, isNewRestaurantsAppInstalled, isStoresAppInstalled, isBookingsAppInstalled } =
      appInstallStatusConfig;

    try {
      if (isOldRestaurantsAppInstalled) {
        await navigateToOldRestaurantsPage(flowAPI);
      } else if (isStoresAppInstalled) {
        await navigateToHomePage(flowAPI);
      } else if (isNewRestaurantsAppInstalled) {
        await navigateToNewRestaurantsPage(flowAPI);
      } else if (isBookingsAppInstalled) {
        await navigateToBookingsPage(flowAPI);
      } else {
        await navigateToHomePage(flowAPI);
      }
    } catch (error) {
      if (error instanceof Error) {
        flowAPI.reportError(error);
      }
      rejectWithValue(error);
    }
  },
);

export const rewardsSlice = createSlice({
  name: 'rewards',
  initialState,
  reducers: {
    setSimpleRewards: (state, action: PayloadAction<SimpleReward[]>) => {
      state.simpleRewards = action.payload;
    },
    addReward: (state, action: PayloadAction<SimpleReward>) => {
      state.simpleRewards.unshift(action.payload);
    },
  },
});

export const rewardsThunk = { applyReward };
