import { RewardType } from '@wix/ambassador-loyalty-v1-reward/types';
import { Tier, TierDefinition, TiersProgramSettings } from '@wix/ambassador-loyalty-v1-tier/types';

export enum RewardOrigin {
  USER = 'USER',
  MEMBER = 'MEMBER',
}

export interface SimpleLoyaltyAccount {
  contactId?: string;
  id?: string;
  memberId?: string | null;
  pointsBalance?: number;
  pointsRedeemed?: number;
  pointsEarned?: number;
  pointsAdjusted?: number;
  tierId?: string | null;
  tiersPeriodPoints?: number;
  pointsExpirationDate?: string;
  pointsExpirationAmount?: number;
}

export interface SimpleReward {
  id?: string | null;
  title?: string;
  description: string;
  requiredPoints?: number;
  type: RewardType | undefined;
  couponActivated: boolean;
  dateCreated?: Date;
  couponCode?: string;
  couponName?: string;
  rewardOrigin: RewardOrigin;
  tierId?: string | null;
  isReferralReward: boolean;
}

export interface TiersProgram {
  programSettings?: TiersProgramSettings;
  tiers?: Tier[];
}

export interface SimpleTier {
  id?: string | null;
  requiredPoints?: number;
  tierDefinition?: TierDefinition;
}

export interface AppsMap {
  [key: string]: boolean;
}
