export enum RewardsTabState {
  Default = 'DEFAULT',
  Empty = 'EMPTY',
}

export enum ButtonsTabState {
  Primary = 'PRIMARY',
  Secondary = 'SECONDARY',
}

export enum RewardBackgroundTabState {
  Pending = 'PENDING',
  History = 'HISTORY',
}
export interface SettingsEvents {
  rewardsTabState: RewardsTabState;
}
