import {
  WIX_BOOKINGS,
  WIX_RESTAURANTS_ORDERS as APP_ID_RESTAURANTS_ORDERS_OLD,
  WIX_RESTAURANTS_ORDERS_NEW as APP_ID_RESTAURANTS_ORDERS_NEW,
} from '@wix/app-definition-ids';
import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';

const WIDGET_ID_RESTAURANTS_ORDERS_OLD = '96254d42-7323-40cb-a7cb-b7c242019728';
const WIDGET_ID_RESTAURANTS_ORDERS_NEW = '5077609c-e47c-4da6-8147-008d243e4499';
const WIDGET_ID_BOOKINGS = '621bc837-5943-4c76-a7ce-a0e38185301f';

interface NavigateToAppSectionParams {
  appDefinitionId: string;
  sectionId: string;
}

const navigateToAppSection = async (
  flowAPI: ControllerFlowAPI,
  { appDefinitionId, sectionId }: NavigateToAppSectionParams,
) => {
  const { wixCodeApi } = flowAPI.controllerConfig;
  let sectionUrl: string | undefined;

  try {
    sectionUrl = (await wixCodeApi.site.getSectionUrl({ appDefinitionId, sectionId }))?.relativeUrl;
  } catch {}

  if (sectionUrl) {
    wixCodeApi.location.to?.(sectionUrl);
  } else {
    await navigateToHomePage(flowAPI);
  }
};

export const navigateToOldRestaurantsPage = async (flowAPI: ControllerFlowAPI) => {
  return navigateToAppSection(flowAPI, {
    appDefinitionId: APP_ID_RESTAURANTS_ORDERS_OLD,
    sectionId: WIDGET_ID_RESTAURANTS_ORDERS_OLD,
  });
};

export const navigateToNewRestaurantsPage = async (flowAPI: ControllerFlowAPI) => {
  return navigateToAppSection(flowAPI, {
    appDefinitionId: APP_ID_RESTAURANTS_ORDERS_NEW,
    sectionId: WIDGET_ID_RESTAURANTS_ORDERS_NEW,
  });
};

export const navigateToBookingsPage = async (flowAPI: ControllerFlowAPI) => {
  return navigateToAppSection(flowAPI, {
    appDefinitionId: WIX_BOOKINGS,
    sectionId: WIDGET_ID_BOOKINGS,
  });
};

export const navigateToHomePage = async (flowAPI: ControllerFlowAPI) => {
  const { wixCodeApi } = flowAPI.controllerConfig;
  const { pages } = await wixCodeApi.site.getSiteStructure({ includePageId: true });
  const homePageId = pages.find(({ isHomePage }) => isHomePage)?.id;

  if (homePageId) {
    wixCodeApi.location.navigateTo?.({ pageId: homePageId });
  }
};
