import { queryReferralRewards } from '@wix/ambassador-loyalty-referral-v1-referral-reward/http';
import { ReferralReward } from '@wix/ambassador-loyalty-referral-v1-referral-reward/types';
import { getCurrentMemberAccount } from '@wix/ambassador-loyalty-v1-account/http';
import { LoyaltyAccount } from '@wix/ambassador-loyalty-v1-account/types';
import { getCurrentMemberCoupons } from '@wix/ambassador-loyalty-v1-coupon/http';
import { LoyaltyCoupon } from '@wix/ambassador-loyalty-v1-coupon/types';
import { getLoyaltyProgram } from '@wix/ambassador-loyalty-v1-program/http';
import { LoyaltyProgram, ProgramStatus } from '@wix/ambassador-loyalty-v1-program/types';
import { listRewards } from '@wix/ambassador-loyalty-v1-reward/http';
import { Reward } from '@wix/ambassador-loyalty-v1-reward/types';
import { getTiersProgram } from '@wix/ambassador-loyalty-v1-tier/http';
import { getCouponEntityName } from '@wix/referrals-reward-descriptions';
import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';

import { TiersProgram } from '../types/domain';
import { AppInstallStatus } from './getAppInstallStatus';

interface LoadDataParams {
  flowAPI: ControllerFlowAPI;
  appInstallStatus: AppInstallStatus;
}

export interface LoadDataResult {
  loyaltyProgram: LoyaltyProgram;
  account: LoyaltyAccount;
  rewards: Reward[];
  claimedCoupons: LoyaltyCoupon[];
  referralCouponRewards: ReferralReward[];
  couponEntityNames: Record<string, string>;
  tiersProgram: TiersProgram;
}

const WARMUP_DATA_KEY = 'loadData';

export const loadData = async ({ flowAPI, appInstallStatus }: LoadDataParams): Promise<LoadDataResult> => {
  const { httpClient } = flowAPI;
  const { wixCodeApi } = flowAPI.controllerConfig;
  const referralsEnabled = appInstallStatus.isReferralsAppInstalled;
  const loyaltyEnabled = appInstallStatus.isLoyaltyAppInstalled;
  const { isSSR, isViewer } = flowAPI.environment;
  const useWarmupData = isViewer;
  const { warmupData } = wixCodeApi.window;

  // Reuse backend API responses from SSR on the client-side
  if (useWarmupData && !isSSR) {
    const dataFromSSR = warmupData.get(WARMUP_DATA_KEY);
    if (dataFromSSR) {
      return dataFromSSR;
    }
  }

  const getLoyaltyProgramPromise = loyaltyEnabled ? httpClient.request(getLoyaltyProgram({})) : undefined;
  const getTiersProgramPromise = loyaltyEnabled ? httpClient.request(getTiersProgram({})) : undefined;
  const getCurrentMemberCouponsPromise = loyaltyEnabled ? httpClient.request(getCurrentMemberCoupons({})) : undefined;

  const getReferralCouponRewardsPromise = referralsEnabled
    ? httpClient
        .request(
          queryReferralRewards({
            contactId: 'me',
            query: {
              filter: {
                rewardType: 'CouponReward',
              },
            },
          }),
        )
        // This method fails when user is not a referred friend or referring customer
        .catch(() => {})
    : undefined;

  const getCurrentMemberAccountPromise = loyaltyEnabled
    ? httpClient.request(getCurrentMemberAccount({})).catch((e) => {
        if (e instanceof Error && e.message.includes('404')) {
          return;
        }

        throw e;
      })
    : undefined;

  const getRewardsPromise = loyaltyEnabled ? httpClient.request(listRewards({})) : undefined;

  const [
    loyaltyProgramResponse,
    tiersProgramResponse,
    currentMemberCouponsResponse,
    currentMemberAccountResponse,
    referralCouponRewardsResponse,
    rewardsResponse,
  ] = await Promise.all([
    getLoyaltyProgramPromise,
    getTiersProgramPromise,
    getCurrentMemberCouponsPromise,
    getCurrentMemberAccountPromise,
    getReferralCouponRewardsPromise,
    getRewardsPromise,
  ]);

  const rewards = rewardsResponse?.data.rewards ?? [];
  const loyaltyProgram = loyaltyProgramResponse?.data.loyaltyProgram ?? {};
  const account = currentMemberAccountResponse?.data.account ?? {
    points: {
      balance: 0,
      redeemed: 0,
      earned: 0,
      adjusted: 0,
    },
    tier: {
      points: 0,
    },
  };

  const claimedCoupons =
    currentMemberCouponsResponse?.data?.loyaltyCoupons?.filter((coupon) => !coupon.couponReference?.deleted) ?? [];

  const referralCouponRewards =
    referralCouponRewardsResponse?.data.referralRewards?.filter(({ coupon }) => !!coupon) ?? [];

  const couponEntityNamePromises = referralCouponRewards.map(({ coupon }) => {
    const couponScope = coupon?.couponSpecification?.scope;
    return couponScope ? getCouponEntityName({ httpClient, couponScope }) : Promise.resolve(null);
  });

  const couponEntityNames: Record<string, string> = {};

  try {
    const couponEntityNameResult = await Promise.all(couponEntityNamePromises);
    couponEntityNameResult.forEach((entityName, index) => {
      if (entityName) {
        const couponId = referralCouponRewards[index].coupon!.id!;
        couponEntityNames[couponId] = entityName;
      }
    });
  } catch {}

  if (loyaltyEnabled && loyaltyProgram.status !== ProgramStatus.ACTIVE) {
    rewards.length = 0;
  }

  const tiersProgram: TiersProgram = {
    programSettings: tiersProgramResponse?.data.programSettings ?? undefined,
    tiers: tiersProgramResponse?.data.tiers ?? undefined,
  };

  const data = {
    loyaltyProgram,
    account,
    rewards,
    claimedCoupons,
    referralCouponRewards,
    couponEntityNames,
    tiersProgram,
  };

  // Forward backend API responses from SSR to client-side
  if (useWarmupData && isSSR) {
    warmupData.set(WARMUP_DATA_KEY, data);
  }

  return data;
};
