import { createSlice } from '@reduxjs/toolkit';
import { LoyaltyProgram } from '@wix/ambassador-loyalty-v1-program/types';

export interface LoyaltyProgramConfigProps {
  program: LoyaltyProgram;
}

const initialState: LoyaltyProgramConfigProps = {
  program: {},
};

export const loyaltyProgramSlice = createSlice({
  name: 'loyaltyProgram',
  initialState,
  reducers: {},
});
