import { createSlice } from '@reduxjs/toolkit';
import { Tier, TiersProgramSettings } from '@wix/ambassador-loyalty-v1-tier/types';

export interface TiersProgramConfigProps {
  programSettings: TiersProgramSettings;
  tiers: Tier[];
}

const initialState: TiersProgramConfigProps = {
  programSettings: {},
  tiers: [],
};

export const tiersProgramSlice = createSlice({
  name: 'tiersProgram',
  initialState,
  reducers: {},
});
