import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { redeemCurrentMemberPointsForCoupon } from '@wix/ambassador-loyalty-v1-coupon/http';
import { LoyaltyCoupon } from '@wix/ambassador-loyalty-v1-coupon/types';
import { RewardType } from '@wix/ambassador-loyalty-v1-reward/types';
import { loyaltyUouApplyRewardClick } from '@wix/bi-logger-loyalty-uou/v2';

import { RewardOrigin, SimpleReward } from '../../../../../../types/domain';
import { RequestStatus, ThunkApiConfig } from '../../../../../../types/store';
import { ToastType } from '../../../../../../types/toast';
import { createClaimedCouponTitleBuilder, sortRewards } from '../../../../../../utils';
import { rewardsSlice } from '..';
import { accountSlice } from '../account/slice';
import { toastThunk } from '../toast/slice';

export interface CouponsConfigProps {
  redeemCouponStatus: RequestStatus;
  claimedCoupons: LoyaltyCoupon[];
}

const initialState: CouponsConfigProps = {
  redeemCouponStatus: RequestStatus.IDLE,
  claimedCoupons: [],
};

const redeemCoupon = createAsyncThunk<LoyaltyCoupon | undefined, SimpleReward, ThunkApiConfig>(
  'coupons/redeemCoupon',
  async (reward, { extra, getState, rejectWithValue, dispatch }) => {
    const { t } = extra.flowAPI.translations;
    const { bi, reportError } = extra.flowAPI;
    const {
      accountConfig: { account },
      rewardsConfig: { simpleRewards },
    } = getState();
    try {
      const {
        data: { coupon },
      } = await extra.flowAPI.httpClient.request(
        redeemCurrentMemberPointsForCoupon({
          rewardId: reward.id!,
        }),
      );

      if (coupon && coupon.rewardName) {
        const params = new URLSearchParams(extra.wixCodeApi.location.url);
        const claimedReward: SimpleReward = {
          id: coupon?.id,
          title: createClaimedCouponTitleBuilder(extra.couponNames)({
            rewardName: coupon.rewardName!,
            coupon: coupon.couponReference!,
          }),
          description: t('app.reward-description.coupon-claimed', { code: coupon.couponReference?.code }),
          type: RewardType.COUPON_REWARD,
          dateCreated: coupon.createdDate!,
          couponActivated: true,
          couponCode: coupon.couponReference?.code,
          couponName: coupon.rewardName,
          rewardOrigin: RewardOrigin.USER,
          isReferralReward: false,
        };
        const pointsRequired = reward.requiredPoints ?? 0;
        const currentBalance = (account.pointsBalance ?? 0) - pointsRequired;

        const tempRewards = sortRewards({
          rewards: simpleRewards,
          pointsBalance: currentBalance,
        });

        tempRewards.unshift(claimedReward);
        dispatch(rewardsSlice.actions.setSimpleRewards(tempRewards));
        dispatch(accountSlice.actions.deductPoints(pointsRequired));
        bi?.report(
          loyaltyUouApplyRewardClick({
            rewardId: coupon.id,
            rewardName: coupon.rewardName,
            rewardType: 'COUPON',
            totalPoints: account.pointsBalance,
            referralInfo: params.has('referralInfo') ? params.get('referralInfo') ?? '' : '',
          }),
        );
        return coupon;
      }
    } catch (error) {
      if (error instanceof Error) {
        reportError(error);
        if (error.message.includes('404')) {
          dispatch(toastThunk.showToast(ToastType.ERROR_UNAVAILABLE_COUPON));
        } else {
          dispatch(toastThunk.showToast(ToastType.ERROR_GENERAL));
        }
      } else {
        dispatch(toastThunk.showToast(ToastType.ERROR_GENERAL));
      }
      return rejectWithValue(error);
    }
  },
);

export const couponsSlice = createSlice({
  name: 'coupons',
  initialState,
  reducers: {
    resetRedeemCouponStatus: (state) => {
      state.redeemCouponStatus = RequestStatus.IDLE;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(redeemCoupon.fulfilled, (state, action) => {
        if (action.payload) {
          state.claimedCoupons.push(action.payload);
        }
        state.redeemCouponStatus = RequestStatus.SUCCESS;
      })
      .addCase(redeemCoupon.pending, (state) => {
        state.redeemCouponStatus = RequestStatus.LOADING;
      })
      .addCase(redeemCoupon.rejected, (state) => {
        state.redeemCouponStatus = RequestStatus.FAILURE;
      });
  },
});

export const couponsThunk = { redeemCoupon };
