import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { ThunkApiConfig } from '../../../../../../types/store';
import { ToastType } from '../../../../../../types/toast';

export interface ToastConfigProps {
  toastType: ToastType;
  isVisible: boolean;
}

const initialState: ToastConfigProps = {
  toastType: ToastType.NONE,
  isVisible: false,
};

const showToast = createAsyncThunk<ToastType, ToastType, ThunkApiConfig>(
  'toast/showToast',
  async (toastType, { dispatch }) => {
    dispatch(toastSlice.actions.hideToast());
    return toastType;
  },
);

export const toastSlice = createSlice({
  name: 'toast',
  initialState,
  reducers: {
    hideToast: (state) => {
      state.isVisible = false;
      state.toastType = ToastType.NONE;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(showToast.fulfilled, (state, action) => {
      state.toastType = action.payload;
      state.isVisible = true;
    });
  },
});

export const toastThunk = { showToast };
